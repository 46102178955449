<template>
    <div class="add-auto-template" v-if="showPage == 'add' || showPage == 'edit'">
        <div class="clearfix"></div>
        <div v-if="isLoading">
            <div class="d-flex justify-content-center">
                <b-spinner label="Loading..."></b-spinner>
            </div>
        </div>
        <v-form v-model="formValid" v-if="!isLoading">
            <v-row>
                <label for="title" class="col-sm-12 col-md-3 text-right control-label col-form-label">
                    Judul
                </label>
                <div class="col-sm-12 col-md-8 m-t-0 p-t-0">
                    <v-text-field class="p-t-0" id="title" v-model="title" :rules="rules.required" />
                </div>
            </v-row>

            <v-row class="mt-0 pt-0">
                <label for="select-template" class="col-sm-12 col-md-3 text-right control-label col-form-label mt-2">
                    Pilih Template
                </label>
                <div class="col-sm-12 col-md-8 m-t-0 p-t-0">
                    <v-autocomplete class="p-t-0" id="select-template" v-model="selectedInputTemplateId"
                        :loading="isLoadingTemplate" :items="inputTemplateList" :search-input.sync="searchInputTemplate"
                        :no-filter="true" item-value="_id" hide-no-data @keyup.enter="searchTemplate"
                        :rules="rules.requiredObject">
                        <template v-slot:selection="data">
                            <span class="ml-2">
                                {{ data.item.title }}
                            </span>
                        </template>
                        <template v-slot:item="data">
                            <div class="d-block" style="width:100%">
                                ({{ data.item.code }}) {{ data.item.title }}
                                <span class="pull-right text-right">
                                    <v-icon v-if="data.item.enable" title="Channel Aktif" color="green" small>
                                        mdi-check-circle</v-icon>
                                    <v-icon v-if="!data.item.enable" title="Channel Inaktif" color="red" small>
                                        mdi-minus-circle</v-icon>
                                </span>
                            </div>
                        </template>
                    </v-autocomplete>
                </div>
            </v-row>
            <div v-if="selectedInputTemplateId">
                <v-row class="mt-0 pt-0" v-for="item in selectedInputTemplate.components" :key="item.paramCcode">
                    <label for="f-user-full-name"
                        class="col-sm-12 col-md-3 text-right control-label col-form-label"></label>
                    <div class="col-sm-12 col-md-8 mt-0 pt-0">
                        <v-text-field v-if="(item.buttonType == 'number')" id="template_title" v-model="item.value"
                            :label="item.paramName" type="number" :rules="[rules.required, rules.counter]"
                            hint="contoh: 081876251425" class="" counter maxlength="13" append-icon="*" />

                        <v-text-field v-else-if="(item.buttonType == 'url')" id="template_title" v-model="item.value"
                            :label="item.paramName" hint="https://www.example.com" :rules="rules.required" class=""
                            append-icon="*" />

                        <v-text-field v-else-if="(item.paramType == 'text')" id="template_title" v-model="item.value"
                            :label="item.paramName" :rules="rules.required" />

                        <div v-else>
                            <v-row>
                                <v-col cols="12" class="">
                                    <v-btn class="btn btn-primary " @click="dialog = true">
                                        Pilih {{ selectedInputTemplate.contentType }}
                                    </v-btn>
                                    <span v-if="selectedHeaders.length < 1" class="float-right text-danger">
                                        Anda belum memilih {{ selectedInputTemplate.contentType }}
                                    </span>
                                </v-col>
                                <v-col cols="12" v-if="selectedInputTemplate.contentType == 'image'">
                                    <div class="col-md-12 p-0" style="margin-right:0">
                                        <div class="list-img-upload" v-if="selectedHeaders.length > 0">
                                            <div class="card card-img" v-for="(e, index) in selectedHeaders"
                                                :key="index">
                                                <img class="images-preview-modal img-responsive" 
                                                    v-bind:src="e.url"
                                                    alt="attachment" />

                                                <div class="wrap-action-img">
                                                    &nbsp;
                                                    <a class="delete-link" @click="selectedHeaders.splice(index, 1);">
                                                        <i class="fa fa-close" title="Hapus"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col v-else>
                                    <div class="col-md-12 list-video pt-0" v-for="(e, index) in selectedHeaders" :key="index">
                                        <div class="row">
                                            <div class="col-md-5" style="width:85%">
                                                <span class="icon-ext pull-left m-r-10">
                                                    <i class="mdi" :class="{
                                                        'mdi-file-document': selectedInputTemplate.contentType == 'document',
                                                        'mdi-file-video': selectedInputTemplate.contentType == 'video'
                                                    }"></i>
                                                </span>
                                                <div class="wrapp-title-doc">{{ e.fileName }}</div>
                                            </div>
                                            <div class="col-md-1">
                                                <a title="hapus" @click="selectedHeaders.splice(index, 1);">
                                                    <span class="text-danger">
                                                        <i title="Hapus" class="fa fa-close"></i>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                        <!-- <v-autocomplete 
                            v-else
                            v-model="item.value" 
                            :loading="isLoadingAttachment" 
                            :items="inputAttachmentList"
                            :search-input.sync="searchInputAttachment"
                            :no-filter="true"
                            item-value="_id"
                            hide-no-data
                            :label="item.paramName"
                            @keyup.enter="searchAttachment"
                            :rules="rules.required"
                        >
                            <template v-slot:selection="data">
                                <span class="ml-2">
                                    {{ data.item.fileName }}
                                </span>
                            </template>
                            <template v-slot:item="data" style="margin:10px">
                                <template v-if="item.paramType != 'image'" style="margin:10px">
                                    {{ data.item.fileName }} - ({{ data.item.size > 1000000 ? (data.item.size/1000000).toFixed(1) + " MB" : data.item.size > 1000 ?  (data.item.size/1000).toFixed(1) + ' kB' : data.item.size + " bytes" }})
                                </template>
                                <template v-else style="margin:10px">
                                    <img :src="data.item.url" alt="attachment" width="100px" class="mb-1" style="max-height:100px; overflow:hidden;">
                                    <span class="ml-4 text-muted">
                                        {{ data.item.fileName }} - ({{ data.item.size > 1000000 ? (data.item.size/1000000).toFixed(1) + " MB" : data.item.size > 1000 ?  (data.item.size/1000).toFixed(1) + ' kB' : data.item.size + " bytes" }})
                                    </span>
                                </template>
                            </template>
                        </v-autocomplete> -->
                    </div>
                </v-row>
            </div>
            <v-row class="mt-0 pt-0">
                <label for="select_group" class="col-sm-12 col-md-3 text-right control-label col-form-label pt-4 mt-2 ">
                    Pilih Grup
                </label>
                <div class="col-sm-12 col-md-8 mt-0 pt-0">
                    <v-autocomplete id="select_group" v-model="selectedInputGrup" :loading="isLoadingGrup"
                        :items="inputGrupList" :search-input.sync="searchInputGrup" :no-filter="true" item-value="_id"
                        @keyup.enter="searchGrup" multiple hide-no-data hide-details>
                        <template v-slot:selection="data">
                            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                                @click:close="
                                removeSelectedItem('group', data.item)
                            ">
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <div class="d-block" style="width:100%">
                                (Anggota {{ data.item.memberCount ? data.item.memberCount : 0 }}) {{ data.item.name }}
                                <span class="pull-right text-right">
                                    <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green"
                                        small>mdi-check-circle</v-icon>
                                    <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red"
                                        small>mdi-minus-circle</v-icon>
                                </span>
                            </div>
                        </template>
                    </v-autocomplete>
                </div>
            </v-row>
            <v-row class="mt-4">
                <label for="select_contact" class="col-sm-12 col-md-3 text-right control-label col-form-label mt-2">
                    Pilih Kontak
                </label>
                <div class="col-sm-12 col-md-8 m-t-0 p-t-0">
                    <v-autocomplete class="p-t-0" id="select_contact" v-model="selectedInputContact"
                        :loading="isLoadingContact" :items="inputContactList" :search-input.sync="searchInputContact"
                        :no-filter="true" item-value="id" @keyup.enter="searchContact" multiple hide-no-data
                        hide-details>
                        <template v-slot:selection="data">
                            <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                                @click:close="
                                removeSelectedItem('contact', data.item)
                            ">
                                {{ data.item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:item="data">
                            <div class="d-block" style="width:100%">
                                {{ data.item.name }} - {{ data.item.primaryPhoneNumber }}
                                <span class="pull-right text-right">
                                    <v-icon v-if="data.item.contactStatus == 'ACTIVE'" title="Channel Aktif"
                                        color="green" small>mdi-check-circle</v-icon>
                                    <v-icon v-if="data.item.contactStatus == 'INACTIVE'" title="Channel Inaktif"
                                        color="red" small>mdi-minus-circle</v-icon>
                                </span>
                            </div>
                        </template>
                    </v-autocomplete>
                </div>
            </v-row>
            <v-row class="mt-4">
                <label for="select_channel" class="col-sm-12 col-md-3 text-right control-label col-form-label mt-2">
                    Pilih Saluran
                </label>
                <div class="col-sm-12 col-md-8 mt-0 pt-0">
                    <v-autocomplete class="p-t-0" id="select_channel" v-model="selectedInputChannel"
                        :items="channelList" item-value="_id" :no-filter="true" hide-no-data :rules="rules.required">
                        <template v-slot:selection="data">
                            <span class="ml-2">
                                {{ data.item.name }}
                            </span>
                        </template>
                        <template v-slot:item="data">
                            <div class="d-block" style="width:100%">
                                {{ data.item.name }}
                                <span class="pull-right text-right">
                                    <v-icon v-if="data.item.status == 'ACTIVE'" title="Channel Aktif" color="green"
                                        small>mdi-check-circle</v-icon>
                                    <v-icon v-if="data.item.status == 'INACTIVE'" title="Channel Inaktif" color="red"
                                        small>mdi-minus-circle</v-icon>
                                </span>
                            </div>
                        </template>
                    </v-autocomplete>
                </div>
            </v-row>
            <div class="form-body" v-if="!$helpers.isBlank(broadcastError)">
                <br>
                <div class="form-material form-filter">
                    <ErrorMessage :error="broadcastError" />
                </div>
            </div>
            <v-row>
                <label for="f-user-full-name" class="col-sm-12 col-md-3 text-right control-label col-form-label">
                </label>
                <div class="col-sm-12 col-md-8" v-if="showPage == 'add'">
                    <v-btn @click="postBroadcast" class="m-r-10 text-white btn btn-primary" :loading="formIsLoading"
                        :disabled="!formValid">
                        Simpan
                    </v-btn>
                    <router-link :to="{ name: 'broadcast' }">
                        <v-btn class="btn btn-light m-r-10">
                            Kembali
                        </v-btn>
                    </router-link>
                    <v-btn @click="resetToEmptyForm" class="btn btn-light m-r-10">
                        Reset
                    </v-btn>
                </div>
                <div class="col-sm-12 col-md-8 mb-2" v-if="showPage == 'edit'">
                    <v-btn @click="editBroadcast" class="m-r-10 text-white btn btn-primary" :disabled="!formValid"
                        :loading="formIsLoading">
                        Simpan
                    </v-btn>

                    <router-link :to="{ name: 'broadcast' }">
                        <v-btn class="btn btn-light m-r-10">
                            Kembali
                        </v-btn>
                    </router-link>
                    <div class="clearfix"></div>
                    <br>
                </div>
            </v-row>
        </v-form>

        <v-dialog v-model="dialog" scrollable max-width="800">
            <v-card>
                <v-toolbar color="deep-purple accent-4" dark>
                    <span class="text-h6 text-white">Lampiran</span>
                </v-toolbar>
                <v-card-text class="m-t-20">
                    <div>
                        <v-row>
                            <v-col cols="12" md="8">
                                <v-text-field class="m-t-0 m-b-0" v-model="searchInputAttachment"
                                    label="Cari nama dokumen" @keyup.enter="searchAttachment" />
                            </v-col>
                            <v-col cols="12" md="4" class="mt-2">
                                <v-btn @click="searchAttachment" class="text-white btn btn-primary"
                                    :loading="isLoadingAttachment" :disabled="isLoadingAttachment">
                                    Cari
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedInputTemplate.contentType == 'image'">
                            <v-col cols="12" md="3" v-for="attch in inputAttachmentList" :key="attch._id">
                                <div>
                                    <div class="wrapp-file">
                                        <img class="img-responsive" :src="attch.url" alt="attachment" width="200"
                                            v-if="attch.fileType == 'image'">
                                        <img class="img-responsive" src="../assets/document.png" alt="attachment"
                                            width="150" v-else>
                                    </div>

                                    <div class="mt-2">
                                        <input type="checkbox" :value="attch" v-model="selectedHeaders" />
                                        <br />
                                        <span class="file-name">fileName: {{ attch.fileName }}</span>
                                        <span>
                                            Size: {{ attch.size > 1000000 ? (attch.size/1000000).toFixed(1) + " MB" : 
                                            attch.size > 1000 ?  (attch.size/1000).toFixed(1) + ' kB' : 
                                            attch.size + " bytes" }}
                                        </span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <div v-else>
                            <div class="row" v-for="attch in inputAttachmentList" :key="attch._id">
                                <div class="col-md-1">
                                    <span class="icon-ext">
                                        <i class="mdi" :class="{
                                            'mdi-file-document': selectedInputTemplate.contentType == 'document',
                                            'mdi-file-video': selectedInputTemplate.contentType == 'video'
                                        }" style="font-size:40px"></i>
                                    </span>
                                </div>
                                <div class="col-md-10">
                                    <div class="wrapp-title-doc" style="width:100%">
                                        {{ attch.fileName }}
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="row">
                                        <div class="col-md-12 pb-0 pt-0">
                                            <small class="text-left  text-uppercase pull-left text-muted">
                                                Size: {{ attch.size > 1000000 ? (attch.size/1000000).toFixed(1) + " MB" : 
                                                attch.size > 1000 ?  (attch.size/1000).toFixed(1) + ' kB' : 
                                                attch.size + " bytes" }}
                                            </small>
                                        </div>
                                        <div class="col-md-12 pt-0">
                                            <small class="title-doc text-muted"></small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <div class="text-right pull-right">
                                        <input type="checkbox" :value="attch" v-model="selectedHeaders" />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn class="btn btn-primary m-2" text @click="dialog = false">
                        Pilih
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import axios from 'axios'
    // import Toast from '../plugins/vuenotification'

    // const uuidv4 = require('uuid/v4');
    // const template = require('lodash.template');

    export default {
        props: ['showPage', 'channelList'],

        components: {},

        data: function () {
            return {
                idBroadcast: String(),
                title: String(),

                selectedInputTemplate: Object(),
                selectedInputTemplateId: String(),
                isLoadingTemplate: false,
                inputTemplateList: Array(),
                searchInputTemplate: String(),

                selectedInputAttachment: String(),
                isLoadingAttachment: false,
                inputAttachmentList: [],
                searchInputAttachment: String(),

                selectedInputGrup: Array(),
                isLoadingGrup: false,
                inputGrupList: Array(),
                searchInputGrup: String(),

                selectedInputContact: Array(),
                isLoadingContact: false,
                inputContactList: Array(),
                searchInputContact: String(),

                selectedInputChannel: String(),
                selectedHeaders: Array(),

                mode: 'add',

                formValid: false,
                isLoading: false,
                formIsLoading: false,
                dialog: false,

                broadcastError: Object(),

                rules: {
                    required: [(v) => !!v || ""],
                    requiredObject: [(v) => Object.keys(v).length > 0 || ""],
                    requiredArray: [(v) => v.length > 0 || ""],
                    counter: v => v.length <= 13 || 'maksimum 13 karakter',
                },
            }
        },

        methods: {
            removeSelectedItem: function (field, item) {
                let index = null
                switch (field) {
                    case "group":
                        index = this.selectedInputGrup.indexOf(item._id)
                        if (index >= 0) this.selectedInputGrup.splice(index, 1)
                        break
                    case "contact":
                        index = this.selectedInputContact.indexOf(item.id)
                        if (index >= 0) this.selectedInputContact.splice(index, 1)
                        break
                }
            },
            isValidForm: function () {
                try {
                    if (this.selectedInputGrup.length == 0 && this.selectedInputContact.length == 0)
                        throw new Error("Pilih minimal 1 grup atau contact");
                    return true
                } catch (error) {
                    this.broadcastError = this.$helpers.createError(error);
                    this.isLoading = false
                    return false
                }
            },
            editBroadcast: async function () {
                this.formIsLoading = true

                this.broadcastError = Object()
                if (!this.isValidForm()) return

                const postData = {
                    title: this.title,
                    channelId: this.selectedInputChannel,
                    messageTemplate: {
                        code: this.selectedInputTemplate.code
                    },
                    params: []
                }

                const addDest = {
                    groupIds: this.selectedInputGrup,
                    contactIds: this.selectedInputContact
                }

                try {
                    const components = this.selectedInputTemplate.components.map((e, i) => {
                        if (this.selectedHeaders.length > 0 && i == 0) {
                            return {
                                code: e.paramCode,
                                values: this.selectedHeaders.map(e => e._id)
                            }
                        } else {
                            return {
                                code: e.paramCode,
                                value: e.value
                            }
                        }
                    })

                    postData.params = components

                    const {
                        data
                    } = await axios.put(process.env.VUE_APP_API_URL + '/broadcast/' + this.idBroadcast, postData)

                    if (data) {
                        await axios.put(process.env.VUE_APP_API_URL + '/broadcast/' + data._id + '/contacts',
                            addDest)
                    }

                    this.resetToEmptyForm()
                    this.$router.push('/broadcast')
                } catch (error) {
                    console.log("post-broadcast", error);
                }
                this.formIsLoading = false
            },
            postBroadcast: async function () {
                this.formIsLoading = true

                this.broadcastError = Object()
                if (!this.isValidForm()) return

                const postData = {
                    title: this.title,
                    channelId: this.selectedInputChannel,
                    messageTemplate: {
                        code: this.selectedInputTemplate.code
                    },
                    params: []
                }

                const addDest = {
                    groupIds: this.selectedInputGrup,
                    contactIds: this.selectedInputContact
                }

                try {
                    const components = this.selectedInputTemplate.components.map((e, i) => {
                        if (this.selectedHeaders.length > 0 && i == 0) {
                            return {
                                code: e.paramCode,
                                values: this.selectedHeaders.map(e => e._id)
                            }
                        } else {
                            return {
                                code: e.paramCode,
                                value: e.value
                            }
                        }
                    })


                    postData.params = components

                    const {
                        data
                    } = await axios.post(process.env.VUE_APP_API_URL + '/broadcast', postData)

                    if (data) {
                        await axios.put(process.env.VUE_APP_API_URL + '/broadcast/' + data._id + '/contacts',
                            addDest)
                    }

                    this.$emit('post-callback', true)
                    this.$router.push('/broadcast')
                    this.resetToEmptyForm()
                } catch (error) {
                    console.log("post-broadcast", error);
                }
                this.formIsLoading = false
            },
            searchAttachment: async function () {
                this.isLoadingAttachment = true

                try {
                    var {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/attachment', {
                        params: {
                            page: 1,
                            term: this.searchInputAttachment,
                            size: 10,
                            fileType: this.selectedInputTemplate.components[0].paramType,
                            sort: 'lastUsedOn',
                            sortDir: 'desc'
                        }
                    });

                    if (this.selectedHeaders.length > 0) {
                        const inputAttachmentList = [...this.selectedHeaders]

                        const newDataFilter = data.content.filter(e => {
                            const index = inputAttachmentList.findIndex(el => el._id == e._id)

                            if (index < 0) {
                                return true
                            } else {
                                return false
                            }
                        })

                        this.inputAttachmentList = [
                            ...inputAttachmentList,
                            ...newDataFilter
                        ]
                    } else {
                        this.inputAttachmentList = data.content
                    }

                } catch (error) {
                    console.log("search-input-attachment", error);
                }
                this.isLoadingAttachment = false
            },
            searchContact: async function () {
                this.isLoadingContact = true

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/contact', {
                        params: {
                            size: 25,
                            page: 1,
                            term: this.searchInputContact,
                            status: 'ACTIVE',
                        },
                    })

                    this.inputContactList.unshift(...data.content)

                } catch (error) {
                    console.log("search-template");
                }
                this.isLoadingContact = false
            },
            searchGrup: async function () {
                this.isLoadingGrup = true

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/group', {
                        params: {
                            size: 25,
                            page: 1,
                            term: this.searchInputGrup,
                            status: 'ACTIVE'
                        },
                    })

                    this.inputGrupList = [...data.content, ...this.inputGrupList]
                } catch (error) {
                    console.log("search-template");
                }
                this.isLoadingGrup = false
            },
            searchTemplate: async function () {
                this.isLoadingTemplate = true
                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/template', {
                        params: {
                            size: 25,
                            page: 1,
                            term: this.searchInputTemplate
                        },
                    })

                    this.inputTemplateList = data.content
                } catch (error) {
                    console.log("search-template");
                }
                this.isLoadingTemplate = false
            },
            getTemplateById: async function (code) {
                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/template/' + code)

                    return data
                } catch (error) {
                    console.log("search-template");
                }
            },
            selectBroadcast: async function (id) {
                this.isLoading = true

                try {
                    const {
                        data
                    } = await axios.get(process.env.VUE_APP_API_URL + '/broadcast/' + id)

                    var template = await this.getTemplateById(data.messageTemplate.code)

                    let temp = []

                    if (data.params && data.params.length > 0) {
                        if (data.params[0].code == 'imageId' || data.params[0].code == 'documentId' || data.params[
                                0].code == 'videoId') {
                            let inputAttachmentList = Array()
                            let selectedHeaders = Array()

                            if (data.params[0].value) {
                                const resAtt = await axios.get(process.env.VUE_APP_API_URL + '/attachment/' + data
                                    .params[0].value)
                                inputAttachmentList.push(resAtt.data)
                                selectedHeaders.push(resAtt.data)
                            } else {
                                for (let i = 0; i < data.params[0].values.length; i++) {
                                    const e = data.params[0].values[i];

                                    const resAtt = await axios.get(process.env.VUE_APP_API_URL + '/attachment/' + e)
                                    inputAttachmentList.push(resAtt.data)
                                    selectedHeaders.push(resAtt.data)
                                }
                            }
                            this.inputAttachmentList = [...inputAttachmentList, ...this.inputAttachmentList]
                            this.selectedHeaders = [...selectedHeaders, ...this.selectedHeaders]
                        }

                        temp = template.components.map((e, idx) => {
                            e.value = data.params[idx].value
                            return e
                        })
                    } else {
                        temp = template.components.map(e => {
                            e.value = String()
                            return e
                        })
                    }

                    template.components = [...temp]
                    this.inputTemplateList = [template]
                    this.selectedInputTemplate = template
                    this.selectedInputTemplateId = template._id

                    this.inputGrupList = data.publishedToGroups
                    this.inputContactList = data.publishedToContacts.map(e => {
                        return {
                            id: e._id,
                            name: e.name
                        }
                    })

                    this.idBroadcast = data._id
                    this.title = data.title
                    this.selectedInputChannel = data.channelId
                    this.selectedInputGrup = data.publishedToGroups.map(e => e._id)
                    this.selectedInputContact = data.publishedToContacts.map(e => e._id)
                } catch (error) {
                    console.log("select brodcast", error);
                }
                this.isLoading = false
            },
            loadInitialData: async function () {
                if (this.$route.params.id != null) {
                    if (this.$route.query.tabs == 'detail') {
                        this.showPage = 'detail'
                    } else {
                        this.showPage = 'edit'
                        // await this.selectBroadcast(decodeURIComponent(this.$route.params.id));
                    }
                } else {
                    if (this.$route.query.tabs == 'add') {
                        this.showPage = 'add'
                    } else {
                        this.showPage = 'list'
                    }
                }
            },
            resetToEmptyForm: function () {
                this.title = String()
                this.selectedInputContact = Array()
                this.inputContactList = Array()
                this.searchInputContact = String()
                this.selectedInputGrup = Array()
                this.inputGrupList = Array()
                this.searchInputGrup = String()
                this.selectedInputTemplate = Object()
                this.selectedInputTemplateId = String(),
                    this.inputTemplateList = Array()
                this.searchInputTemplate = String()
                this.inputAttachmentList = Array(),
                    this.searchInputAttachment = String(),
                    this.selectedInputChannel = String()
                this.idBroadcast = String()
                this.selectedHeaders = Array()
                this.inputAttachmentList = Array()
                this.searchInputAttachment = String()
                this.broadcastError = Object()
            },
        },

        watch: {
            selectedInputTemplateId: function (val) {
                if (val) {
                    const selectedTemplate = this.inputTemplateList.find(e => e._id == val)
                    if (this.showPage != 'edit') {
                        this.selectedHeaders = Array()
                        this.inputAttachmentList = Array()
                        if (Object.keys(selectedTemplate).length != 0) {
                            const temp = selectedTemplate.components.map(e => {
                                e.value = String()
                                return e
                            })

                            selectedTemplate.components = temp
                            this.selectedInputTemplate = selectedTemplate
                        }
                    }
                }
            },
            showPage: async function (val) {
                this.resetToEmptyForm()
                if (val == 'edit') await this.selectBroadcast(decodeURIComponent(this.$route.params.id));
            },
        },

        mounted: async function () {
            this.loadInitialData();
        },
    }
</script>